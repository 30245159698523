@media screen and (max-width: 320px) {
  .container-nav {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
  .logo {
    width: 150px;
    margin: 0 auto;
    height: 10rem;
  }
  .navbar {
    position: absolute;
    margin-left: 30px;
    margin-top: 20px;
  }
}
@media screen and (min-width: 321px) and (max-width: 380px) {
  .logo {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 820px) {
  .google-btn {
    width: 60px !important;
    height: 0px !important;
  }
  .google-icon-wrapper {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  .google-icon {
    margin-top: 6px !important;
    margin-left: 6px !important;
  }
  .btn-text {
    display: none;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.google-btn {
  width: 155px;
  height: 42px;
  background-color: #2a2243;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  top: -12px;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #5442f4;
}
.google-btn:active {
  background: #5442f4;
}
