#myInput {
  background-image: url("https://cdn-icons-png.flaticon.com/512/64/64673.png");
  background-size: 25px;
  background-position: 10px 12px;
  background-repeat: no-repeat;
  width: 50%;
  border-radius: 30px;
  margin-top: 20px;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  #myInput {
    width: 12%;
  }
}
