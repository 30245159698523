.card {
  width: 95%;
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: #f1ebfa;
}

.card-hov:hover {
  transform: translateY(-7px);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
}

.card::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.card-title,
.card-subtitle,
.card-text {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: nunito, helvetica, Arial, sans-serif;
}

.card-title {
  font-weight: 800;
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 2rem;
}

.card-subtitle {
  font-weight: 400;
  margin-top: 0.2rem;
  font-size: 1.7rem;
}

.card-text {
  font-weight: bold;
  margin-top: 1rem;
}

.card-labels {
  text-align: center;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid black;
  padding: 5px;
  border-radius: 1rem;
  margin-bottom: 5px;
}

.labels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.2rem;
  align-items: flex-end;
  margin-bottom: 1rem;
  padding: 1rem;
}

.card-labels {
  margin: 3px;
  padding: 5px;
}

.card-opener {
  color: black;
  text-decoration: none;
}

.verified-text {
  color: #3897f0;
  font-weight: 500;
}
.img-verified {
  margin-right: 3px;
  display: inline;
}

@media screen and (min-width: 1100px) {
  .card {
    margin-right: 1rem;
    margin-left: 2.5rem;
    width: 25%;
  }
  .cards-container {
    justify-content: center;
  }
}

.logo_company {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 10px;
  margin-top: 8px;
  object-fit: contain;
}
