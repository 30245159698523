.logo-box {
  cursor: pointer;
  border: 1px solid #ddd;
  width: 150px;
  height: 150px;
  /* border-radius: 6px; */
  border-radius: 100%;
  background: #fbfbfb;
  /* background-size: 100%; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 14px;
  position: relative;
}

.logo-box input {
  cursor: pointer;
  appearance: none;
  width: 100%;
  height: 100%;
  font-size: 24px;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.logo-text {
  position: absolute;
  top: 44%;
  left: 15%;
}

.inactive-opacity {
  opacity: 0.5;
}

.active-opacity {
  opacity: 1;
}

.desktopWidth {
  min-width: 370px;
  min-height: 270px;
}

@media screen and (max-width: 370px) {
  .desktopWidth {
    min-width: 290px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#reset {
  display: none;
}
