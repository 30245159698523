@media screen and (max-width: 375px) {
  .littleScreen-first {
    margin-top: 0;
    padding-top: 5rem;
  }
  .littleScreen-second {
    margin-top: 2rem;
  }
  .littleScreen-third {
    margin-top: 0.4rem;
  }
}
