@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
.header-section {
  height: 10vh;
}
.bg-mycolor {
  background-color: #2a2243;
}

.bg-button {
  background-color: #503b7c;
}

.bg-button:hover {
  background-color: #2a2243;
}

.banner-h {
  height: 90vh;
}

.hover_color:hover {
  color: #5c4a94;
}

.jobs-section {
  background-color: #fbfafd;
  min-height: 400px;
}
.cards-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 20px;
}

/* ANIMATIONS */

.my_animation {
  animation: moveInLeft 1s ease-out;
}
.video-animation {
  animation: moveInRight 1s ease-out;
}

.bottom-animation {
  animation: moveInBottom 1s ease-out;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
